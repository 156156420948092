import * as React from 'react';
import { Link } from 'gatsby';


export default function WhatIsGatsby() {

    return (
        <section className="background-animate overflow-hidden h-screen flex bg-gradient-to-r from-gray-50 to-pink-200 background-animate"><section>
        </section><div className="container mx-auto px-4">
            <div className="flex flex-wrap items-center pt-32 pb-36 h-full max-w-7xl m-auto">
            <div className="w-full lg:w-1/2 p-6">
                <div className="lg:max-w-xl">
                <p className="mb-8 font-heading max-w-max px-5 py-2.5 uppercase font-semibold text-xs tracking-wider text-white bg-[#e76f51] rounded-full">Technology</p>
                <h1 className="mb-6 font-heading text-7xl md:text-10xl xl:text-12xl text-[#264653] font-bold" >What is GatsbyJs?</h1>
                <p className="mb-9 text-gray-600 text-lg" >GatsbyJS is a static site generator that allows me to build blazing fast websites.</p>
                <Link to="/contact/">
                    <button className="group relative font-heading px-10 py-5 w-full lg:w-auto uppercase text-white text-xs font-semibold tracking-px bg-[#e76f51]  overflow-hidden rounded-md">
                        <div className="absolute top-0 left-0 transform -translate-x-full group-hover:-translate-x-0 h-full w-full transition ease-in-out duration-500 bg-gray-800" />
                        <p className="relative">Contact Now</p>
                    </button>
                </Link>
                </div>
            </div>
            <div className="w-full lg:w-1/2 p-6">
                <img className="block mx-auto" src="https://www.gatsbyjs.cn/static/gatsby-icon-4a9773549091c227cd2eb82ccd9c5e3a.png" alt="gatsby Icon" /></div>
            </div>
        </div>
    </section>

    )
}