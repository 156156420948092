import * as React from 'react';


export default function GatsbyFeatures() {

    return (
        <section className="relative pt-24 pb-32 overflow-hidden bg-gray-50">
            <div className="container mx-auto px-4 mb-12">
                <h2 className="mb-24 font-heading font-semibold text-center text-5xl sm:text-6xl text-[#264653]" >Gatsby is defining performance on the web.</h2>
                <div className="flex flex-wrap -m-8 max-w-4xl mx-auto">
                <div className="w-full md:w-1/2 p-8 border-b md:border-r border-[#2a9d8f] bg-white">
                    <a className="group" href="#">
                    </a><div className="flex flex-wrap -m-4"><a className="group" href="#">
                        <div className="w-auto p-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#e76f51" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                        </svg>
                        </div>
                    </a><div className="flex-1 p-4"><a className="group" href="#">
                        <h3 className="mb-5 font-heading font-bold text-[#2a9d8f] text-lg group-hover:underline" >Performance</h3>
                        <p className="mb-5 text-gray-600 text-base text-justify" >Gatsbyjs is a performant static site generator. It statically renders your pages so that your users never have to wait for a page to load. This makes for excellent SEO efficiancy and a great user expirience.</p>
                        </a><a className="group inline-block font-heading font-medium text-gray-900 hover:text-gray-800 text-base overflow-hidden" href="#">
                        <div className="w-full transform -translate-x-0 group-hover:translate-x-full h-px bg-black transition ease-in-out duration-500" />
                        </a>
                    </div>
                    </div>
                </div>
                <div className="w-full md:w-1/2 p-8 border-b border-[#2a9d8f]">
                    <a className="group" href="#">
                    </a><div className="flex flex-wrap -m-4"><a className="group" href="#">
                        <div className="w-auto p-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#e76f51" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                        </svg>
                        </div>
                    </a><div className="flex-1 p-4"><a className="group" href="#">
                        <h3 className="mb-5 font-heading font-bold text-[#2a9d8f] text-lg group-hover:underline" >Security</h3>
                        <p className="mb-5 text-gray-600 text-base text-justify" >Gatsby uses react to render HTML elemnts from JSX. JSX escapes HTML tags by default, making Cross-Site Scripting difficult to execute.&nbsp;</p>
                        </a>
                    </div>
                    </div>
                </div>
                <div className="w-full md:w-1/2 p-8 border-b md:border-b-0 md:border-r border-[#2a9d8f]">
                    <a className="group" href="#">
                    </a><div className="flex flex-wrap -m-4"><a className="group" href="#">
                        <div className="w-auto p-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#e76f51" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                        </svg>
                        </div>
                    </a><div className="flex-1 p-4" ><a className="group" href="#">
                        <h3 className="mb-5 font-heading font-bold text-[#2a9d8f] text-lg group-hover:underline" >Easy data handling</h3>
                        <p className="mb-5 text-gray-600 text-base text-justify" >Data from multiple source can be easily intergrated using gatsbys' 'data layer', meaning information from a wordpress blog, a mysql database, drupal CMS and shopify store can all coexist via a low effort development expirence.&nbsp;</p>
                        </a><a className="group inline-block font-heading font-medium text-gray-900 hover:text-gray-800 text-base overflow-hidden" href="#">
                        <div className="w-full transform -translate-x-0 group-hover:translate-x-full h-px bg-black transition ease-in-out duration-500" />
                        </a>
                    </div>
                    </div>
                </div>
                <div className="w-full md:w-1/2 p-8 bg-white">
                    <a className="group" href="#">
                    </a><div className="flex flex-wrap -m-4"><a className="group" href="#">
                        <div className="w-auto p-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#e76f51" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                        </div>
                    </a><div className="flex-1 p-4"><a className="group" href="#">
                        <h3 className="mb-5 font-heading font-bold text-[#2a9d8f] text-lg group-hover:underline" >Easy SEO</h3>
                        <p className="mb-5 text-gray-600 text-base text-justify" >Gatsby SEO features, such as easy accass and manipulation of page metadata, allow every page to contribute their part to the sites' presence online.&nbsp;</p>
                        </a><a className="group inline-block font-heading font-medium text-gray-900 hover:text-gray-800 text-base overflow-hidden" href="#">
                        <div className="w-full transform -translate-x-0 group-hover:translate-x-full h-px bg-black transition ease-in-out duration-500" />
                        </a>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>

    )
}