import * as React from 'react';

import Head from '../../pageSections/templates/head/standard';

import * as Components from '../../pageSections/technology';
import Template from '../../pageSections/templates/secondary';

import { graphql } from 'gatsby';

export default function Home({ data }) {

    const [siteSettings, setSiteSettings] = React.useState({});

    React.useEffect(() => setSiteSettings(JSON.parse(data.datoCmsWebsite.settings)), []);

    return (
        <Template>
            {/* place your sections here from both Template and Page imports */}
            <Head PageName={'Technology'} SiteSettings={JSON.parse(data.datoCmsWebsite.settings)} />

            <Components.WhatIsGatsby />
            <Components.GatsbyFeatures />
            <Components.Intergrations />
            <Components.LearnGatsby />
        </Template>
    )
}


export const query = graphql`
    query TechnologyPageQuery($sitename: String) {
        datoCmsWebsite(name: { eq: $sitename }) {
            id
            name
            settings
        }
    }
`