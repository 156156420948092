import * as React from 'react';

import { Link } from 'gatsby';

export default function LearnGatsby() {

    return (
        <section className="relative py-36 overflow-hidden bg-gradient-to-l from-gray-50 to-pink-100 background-animate">
        <div className="container mx-auto px-4 max-w-7xl">
            <div className="p-16 bg-gradient-cyan2 rounded-10">
            <div className="flex flex-wrap items-center justify-between -m-6">
                <div className="w-full md:w-1/2 p-6">
                <div className="md:max-w-md">
                    <h2 className="font-heading font-bold text-5xl sm:text-6xl text-[#264653]">Want to learn more?</h2>
                    <p className="text-lg font-semibold text-gray-900 mt-8">Learn More About GatsbyJS by diving into thier documentation</p>
                </div>
                </div>
                <div className="w-full md:w-1/2 p-6">
                <div className="flex flex-wrap justify-end -m-2">
                    <div className="w-full p-2">

                    <Link to={'https://www.gatsbyjs.com/why-gatsby/'}>
                        <button className="font-heading py-5 px-10 block w-full uppercase text-xs tracking-px text-white font-bold bg-[#2a9d8f] hover:bg-gray-50 hover:text-[#2a9d8f] border border-[#2a9d8f] rounded-md">Learn</button>
                    </Link>
                    </div>
                    <div className="w-full xl:w-auto p-2">
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
    )
}